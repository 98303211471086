import React, { useState, useRef, useEffect } from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CardWrapper from "../../components/CardWrapper";
import { routes } from "../../App";
import { ForwardArrowIcon } from "../../assets/icons";
import { ServiceList } from "../../api/interfaces/Service";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import {
  InternetIcon,
  LearningIcon,
  NetworkIcon,
  HomeSecurityIcon,
  ChevronArrowIcon,
} from "../../assets/icons";
import useViewport from "../../hooks/useViewport";

type AllServicesProps = {
  services: ServiceList | undefined;
} & React.HTMLAttributes<HTMLDivElement>;

const ViewMoreButtonLink = styled(Link)`
  font-family: "Inter";
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500;
  border: 1px solid #d0d5dd;
  color: inherit;
  text-decoration: none;
  border-radius: 4px;
  padding: 6px 12px;
  :hover {
    border: 1px solid #d0d5dd;
    background: rgba(208, 213, 221, 0.043);
  }
`;

const AllServices = ({ services }: AllServicesProps) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const navigate = useNavigate();
  const { isMobile } = useViewport();

  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const gridContainerRef = useRef<HTMLDivElement>(null);

  const checkForOverflow = () => {
    if (gridContainerRef.current) {
      const isOverflowing =
        gridContainerRef.current.scrollWidth >
        gridContainerRef.current.clientWidth;
      setShowScrollIndicator(isOverflowing);
    }
  };

  useEffect(() => {
    checkForOverflow(); // Check on mount
    window.addEventListener("resize", checkForOverflow); // Add resize listener

    return () => {
      window.removeEventListener("resize", checkForOverflow); // Clean up listener
    };
  }, []);

  const arrowAction = (
    <Link
      to={routes.services.path}
      aria-label="View All Available Services"
      data-cy="dashboard-all-services"
    >
      <ForwardArrowIcon fill={colors.icons.default.fillColor} />
    </Link>
  );

  return (
    <Grid item xl={12} style={{ height: "fit-content" }}>
      <CardWrapper
        title="All Services"
        actions={arrowAction}
        sx={{
          maxHeight: {
            sm: "265px",
            xs: "500px",
          },
        }}
      >
        <Grid
          container
          ref={gridContainerRef}
          sx={{
            marginTop: "12px",
            paddingBottom: "12px",
            paddingLeft: 2,
            overflow: "auto", // Enable horizontal scrolling
            display: "flex", // Make it a flex container
            flexWrap: "nowrap", // Prevent items from wrapping
            paddingRight: showScrollIndicator ? 30 : 0,
            flexDirection: {
              sm: "row",
              xs: "column",
            },
            height: {
              sm: "auto",
              xs: "400px",
            },
            gap: 3,
          }}
          spacing={2}
        >
          {services?.data?.servicetype?.map((servicetype) => {
            const cyText = `service-view-${servicetype.name
              .toLocaleLowerCase()
              .replace(" ", "-")}`;
            return (
              servicetype.display && (
                <Box
                  key={servicetype.id}
                  id={servicetype.id}
                  sx={{
                    textAlign: "center",
                    minHeight: {
                      sm: "150px",
                      xs: "auto",
                    },
                    width: {
                      sm: "160px",
                      xs: "auto",
                    },
                    flexShrink: 0,
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: {
                      sm: "column",
                      xs: "row",
                    },
                    alignItems: "center",
                    justifyContent: {
                      sm: "end",
                      xs: "space-between",
                    },
                    gap: 1,
                    border: "1px solid #DBDBDB",
                    padding: {
                      sm: "20px 0px",
                      xs: "20px 18px",
                    },
                    "&:hover": {
                      boxShadow: "3.44px 9.87px 16.87px 0px #7E828163",
                      shadowOpacity: 1,
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "80%",
                    }}
                  >
                    {servicetype.name === "Internet Service" && (
                      <InternetIcon fill={colors.icons.default.fillColor} />
                    )}
                    {servicetype.name === "Remote Learning" && (
                      <LearningIcon fill={colors.icons.default.fillColor} />
                    )}
                    {servicetype.name === "Network Service" && (
                      <NetworkIcon fill={colors.icons.default.fillColor} />
                    )}
                    {servicetype.name === "Home Security" && (
                      <HomeSecurityIcon fill={colors.icons.default.fillColor} />
                    )}
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 400,
                        fontSize: {
                          sm: "14px",
                          xs: "12px",
                        },
                      }}
                    >
                      {servicetype.name}
                    </Typography>
                  </Box>
                  <ViewMoreButtonLink
                    sx={{
                      width: {
                        sm: "auto",
                        xs: "40%",
                      },
                      maxWidth: {
                        sm: "none",
                        xs: "80px",
                      },
                    }}
                    to={`${routes.services.path}?filter=${encodeURI(
                      servicetype.name
                    )}`}
                    data-cy={cyText}
                  >
                    View {isMobile ? "" : "More"}
                  </ViewMoreButtonLink>
                </Box>
              )
            );
          })}
          {showScrollIndicator && (
            <Box
              sx={{
                display: "flex",
                backgroundColor: "white",
                position: "absolute",
                height: "160px",
                width: "36px",
                right: "25px",
                alignItems: "center",
              }}
            >
              <ChevronArrowIcon fill={colors.icons.default.fillColor} />
            </Box>
          )}
        </Grid>
      </CardWrapper>
    </Grid>
  );
};
export default AllServices;
