import { useState, useEffect } from "react";
import CardWrapper from "../../components/CardWrapper";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { Stack, Typography, Grid, Box } from "@mui/material";
import { ProfileSettingsModal } from "../../components/CustomModal";
import { useGetNotifications } from "../../hooks/useGetNotifications";
import { useGetUnreadMessageCount } from "../../hooks/useGetUnreadMessageCount";
import useGetUserId from "../../hooks/useGetUserId";
import NotificationRow, {
  NotificationSettings,
  ClearNotifications,
} from "../../components/Notification";
import useGetThemePath from "../../hooks/useGetThemePath";

type ContentType = "notifications" | "settings" | "clear";

const Notifications = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const [content, setContent] = useState<ContentType>("notifications");

  const [showModal, setShowModal] = useState(false);

  const userId = useGetUserId();
  const { data, refetch: refetchNotifications } = useGetNotifications(userId);
  const { data: unread, refetch: refetchUnread } =
    useGetUnreadMessageCount(userId);

  const viewAction = (
    <Box
      component="button"
      sx={{
        fontFamily: "Inter",
        fontStyle: "normal",
        marginRight: getREMFromPX(3),
        background: "#fff",
        border: `1px solid ${colors.buttons.outline.border}`,
        fontSize: 14,
        fontWeight: 500,
        color: colors.form.primary,
        borderRadius: "4px",
        marginLeft: "auto",
        padding: "4px 16px",
        cursor: "pointer",
      }}
      onClick={() => {
        setContent("settings");
        setShowModal(true);
      }}
    >
      Update Notification Settings
    </Box>
  );

  const notifications = data?.data?.messageportaluser?.map?.((notification) => (
    <NotificationRow key={notification.id} notification={notification} />
  ));
  const unreadCount = unread?.data?.messageportaluser?.unread_count ?? 0;

  const closeModal = () => {
    setShowModal(false);
    refetchNotifications();
    refetchUnread();
  };

  const noNotifications = (
    <Typography
      sx={{
        fontFamily: "Inter",
        fontSize: getREMFromPX(16),
        fontWeight: "500",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "left",
        color: "#9b9b9b",
      }}
    >
      No New Notifications
    </Typography>
  );

  const getModalContent = () => {
    switch (content) {
      case "clear":
        return (
          <ClearNotifications
            notifications={data?.data?.messageportaluser}
            closeModal={closeModal}
            setContent={setContent}
          />
        );
      case "settings":
        return (
          <NotificationSettings
            setContent={setContent}
            closeModal={closeModal}
          />
        );
      default:
        return (
          <>
            {notifications?.length ? (
              <Stack sx={{ maxHeight: getREMFromPX(300), overflow: "auto" }}>
                {notifications}
              </Stack>
            ) : (
              noNotifications
            )}
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: getREMFromPX(20),
              }}
            >
              <Typography
                onClick={() => setContent("clear")}
                sx={{
                  cursor: "pointer",
                  fontFamily: "Inter",
                  fontSize: getREMFromPX(16),
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  textAlign: "right",
                  marginLeft: "auto",
                  color: colors.links.teal,
                }}
              >
                Clear All
              </Typography>
            </Stack>
          </>
        );
    }
  };

  useEffect(() => {
    if (!showModal) {
      setContent("notifications");
    }
  }, [showModal]);

  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <ProfileSettingsModal
        title="Notifications"
        open={showModal}
        onClose={closeModal}
      >
        {getModalContent()}
      </ProfileSettingsModal>
      <CardWrapper
        title="Notifications"
        actions={viewAction}
        sx={{ minHeight: getREMFromPX(167), padding: "10px 20px" }}
      >
        <Stack sx={{ marginTop: getREMFromPX(24) }}>
          {notifications?.length ? (
            <>
              <Box
                sx={{
                  fontFamily: "Inter",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {notifications?.[0]}
                <Typography
                  onClick={() => setShowModal(true)}
                  sx={{
                    cursor: "pointer",
                    fontSize: getREMFromPX(16),
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: colors.links.teal,
                  }}
                >
                  View
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: getREMFromPX(14),
                  fontWeight: "500",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  textAlign: "left",
                  color: "#9b9b9b",
                }}
              >
                ({unreadCount}) Unread Messages
              </Typography>
            </>
          ) : (
            noNotifications
          )}
        </Stack>
      </CardWrapper>
    </Grid>
  );
};
export default Notifications;
