import { useCallback, useContext, useState } from "react";
import Button from "../../../components/Button";
import { Plan } from "../utils/intefaces/Plan";
import { Box, TableCell, TableRow, Typography, styled } from "@mui/material";
import { renderStars } from "../utils/getStars";
import { theme } from "../../../theme";
import getREMFromPX from "../../../utils/getREMFromPX";
import { LogoContainer } from "./CardService";
import { useDeleteSubscription } from "../../../hooks/useDeleteSubscription";
import { useDeleteReview } from "../../../hooks/useDeleteReview";
import useGetUserId from "../../../hooks/useGetUserId";
import sendErrorToast from "../../../utils/sendErrorToast";
import { useQueryClient } from "react-query";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../components/AddressAndAccountProvider";
import UnsubscribePopup from "./UnsubscribePopup";
import { Review } from "../../../api/interfaces/Review";
import { InfoButtonIcon } from "../../../assets/icons";
import ReviewModal from "../../../components/ReviewModal";
import Menu from "../../../components/Menu";
import MenuItem from "../../../components/MenuItem";
import useMenuAnchor from "../../../hooks/useMenuAnchor";
import { getThemePath } from "../../../hooks/useGetThemePath";

const themePath = getThemePath();
const colors = theme[themePath].colors;

const StyledParagraph = styled("p")`
  color: ${colors.textPrimary.heading};
  font-family: "Inter";
  font-size: ${getREMFromPX(15)};
`;

const menuListProps = {
  "aria-labelledby": "profile_options_button",
};

const MySubscriptionsRow = ({
  total_reviews,
  ratings_sum,
  serviceType,
  planName,
  port,
  providerId,
  price,
  currentIndex,
  unsubscribeId,
  review,
  historyId,
  dataCy,
  isUserRatingEnabled,
}: Partial<Plan> & {
  serviceType: string;
  port: string;
  price: string;
  currentIndex: number;
  unsubscribeId: string;
  review?: Review;
  historyId: string;
  dataCy?: string;
  isUserRatingEnabled: boolean;
}) => {
  const { currentAccount } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
  const queryClient = useQueryClient();
  const userId = useGetUserId();
  const { mutate: deleteSubscription, isLoading: isDeleting } =
    useDeleteSubscription({
      onSuccess: () => {
        setSelectedSubscriptionId("");
        queryClient.invalidateQueries([
          "subscriptions",
          userId,
          currentAccount.id,
        ]);
      },
      onError: () => {
        setSelectedSubscriptionId("");
        sendErrorToast(
          "There was a problem deleting the subscription, please try again"
        );
      },
    });
  const { mutate: deleteReview } = useDeleteReview(currentAccount.id);

  const reviewsAverage =
    ratings_sum && Number(total_reviews) !== 0
      ? (Number(ratings_sum) / Number(total_reviews)).toString()
      : "0";

  const stars = renderStars(reviewsAverage);
  const toggleModal = useCallback(
    (id = "") => {
      if (isDeleting) {
        return;
      }
      setSelectedSubscriptionId((oldId) => (oldId ? "" : id));
    },
    [isDeleting]
  );

  const [openModal, setOpenModal] = useState(false);

  const {
    anchorEl: reviewMenuAnchorEl,
    open: reviewOpenMenu,
    handleClick: handleReviewMenuClick,
    handleClose: handleReviewMenuClose,
  } = useMenuAnchor();

  const closeModal = useCallback(() => {
    handleReviewMenuClose();
    setOpenModal(false);
  }, [handleReviewMenuClose]);

  return (
    <>
      <ReviewModal
        open={openModal}
        closeModal={closeModal}
        review={review}
        planName={planName ?? ""}
        historyId={historyId as string}
      />
      <TableRow data-cy={dataCy}>
        <TableCell data-cy={`my-services-row-${currentIndex}-port`}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              border: "1px solid #efefef",
              width: "65px",
              height: "28px",
            }}
          >
            <Typography
              color={colors.textPrimary.heading}
              fontSize={getREMFromPX(12)}
              fontFamily="Inter"
              fontWeight={500}
              textAlign="center"
            >
              {port}
            </Typography>
            <Box
              sx={{
                color: "#84e88e",
                background: "#84e88e",
                width: "6.6px",
                height: "6.6px",
                borderRadius: "10px",
                border: "1px solid #84e88e",
                marginLeft: "4px",
              }}
            >
              &nbsp;
            </Box>
          </Box>
        </TableCell>
        <TableCell data-cy={`my-services-row-${currentIndex}-provider`}>
          <LogoContainer width="105" height="55">
            <img
              src={`${process.env.PUBLIC_URL}/assets/provider/${providerId}.png`}
              alt={planName + " " + providerId}
            />
          </LogoContainer>
        </TableCell>
        <TableCell data-cy={`my-services-row-${currentIndex}-plan`}>
          <StyledParagraph>{planName}</StyledParagraph>
        </TableCell>
        <TableCell data-cy={`my-services-row-${currentIndex}-type`}>
          <StyledParagraph>{serviceType}</StyledParagraph>
        </TableCell>
        <TableCell data-cy={`my-services-row-${currentIndex}-price`}>
          ${price ?? "0.00"}
        </TableCell>
        {isUserRatingEnabled ? (
          <TableCell data-cy={`my-services-row-${currentIndex}-review`}>
            {reviewsAverage === "0" ? (
              <>
                <StyledParagraph
                  style={{
                    fontWeight: "500",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontSize: getREMFromPX(14),
                    color: "#1D19E0",
                  }}
                  onClick={(event) => {
                    if (review) {
                      handleReviewMenuClick(event);
                    } else {
                      setOpenModal(true);
                    }
                  }}
                  data-cy={`my-services-row-${currentIndex}-add-review`}
                >
                  {review ? (
                    <Box
                      sx={{
                        paddingLeft: 2,
                      }}
                    >
                      <InfoButtonIcon fill={colors.icons.default.fillColor} />
                    </Box>
                  ) : (
                    "Add Review"
                  )}
                </StyledParagraph>
                <Menu
                  id="review-menu"
                  anchorEl={reviewMenuAnchorEl}
                  open={reviewOpenMenu}
                  onClose={handleReviewMenuClose}
                  MenuListProps={menuListProps}
                >
                  <MenuItem
                    onClick={() => {
                      handleReviewMenuClose();
                      setOpenModal(true);
                    }}
                  >
                    Edit Review
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleReviewMenuClose();
                      deleteReview(review?.id ?? "");
                    }}
                  >
                    Delete Review
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <StyledParagraph>{stars}</StyledParagraph>
            )}
          </TableCell>
        ) : null}
        <TableCell data-cy={`my-services-row-${currentIndex}-unsubscribe`}>
          <Button
            text="Unsubscribe"
            mode="outline"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedSubscriptionId(providerId ?? "");
            }}
          />
        </TableCell>
      </TableRow>
      <UnsubscribePopup
        open={!!selectedSubscriptionId}
        onClickSubscribe={() => deleteSubscription(unsubscribeId)}
        onClose={() => toggleModal()}
        isLoading={isDeleting}
        name={serviceType as string}
        price={price as string}
        selectedId={providerId as string}
      />
    </>
  );
};

export default MySubscriptionsRow;
