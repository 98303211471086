import React, { useContext } from "react";
import MyServicesCard from "./MyServicesCard";
import { useGetMySubscriptions } from "../../hooks/useGetMySubscriptions";
import useGetUserId from "../../hooks/useGetUserId";
import sendErrorToast from "../../utils/sendErrorToast";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";
import { Grid, colors, Stack } from "@mui/material";
import CardWrapper from "../CardWrapper";

const processData = (subscriptionsData: any) => {
  const subscriptions = subscriptionsData?.data?.subscription || [];

  return subscriptions.map((subscriptionData: any) => {
    return {
      serviceType: subscriptionData.Serviceplan?.Service?.Servicetype?.name,
      serviceName: subscriptionData.Serviceplan?.fields?.Name,
      providerName: subscriptionData.Serviceplan?.Service?.Provider?.name,
      serviceLogo: `${process.env.PUBLIC_URL}/assets/provider/${subscriptionData.Serviceplan?.Service?.Provider?.id}.png`,
      devicePort: subscriptionData.Portinterface?.display_name,
      deviceName: `${subscriptionData.Portinterface?.Device?.Physicallocation?.house_number} ${subscriptionData.Portinterface?.Device?.Physicallocation?.street}`,
    };
  });
};

const MyServicesCardIndex = () => {
  const userId = useGetUserId();
  const { selectedAccountId } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { data: subscriptionsData, isLoading } = useGetMySubscriptions(
    userId,
    selectedAccountId,
    {
      enabled: !!userId && !!selectedAccountId,
      refetchOnWindowFocus: false,
      onError: () =>
        sendErrorToast(
          "There was an error getting the subscriptions information, please try again"
        ),
    }
  );
  if (isLoading) {
    return (
      <Grid item xl={12}>
        <CardWrapper title="My Services">
          <Stack
            spacing="1rem"
            width="100%"
            sx={{ minHeight: 280, overflow: "auto", mt: "1rem" }}
          >
            ...
          </Stack>
        </CardWrapper>
      </Grid>
    );
  }

  return <MyServicesCard subscriptions={processData(subscriptionsData)} />;
};

export default MyServicesCardIndex;
