import "./App.css";
import { Route, Routes } from "react-router-dom";
import "typeface-nunito-sans";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import GlobalStyles from "@mui/material/GlobalStyles";
import { globalStyle } from "./GlobalStyle";
import Header from "./components/Header";
import Login from "./routes/Login";
import ForgotPassword from "./routes/ForgotPassword";
import AccountSettings from "./routes/AccountSettings";
import { ToastContainer } from "react-toastify";
import AuthProvider from "./components/AuthProvider";
import BillingProvider from "./components/BillingProvider";
import "react-toastify/dist/ReactToastify.min.css";
import ProtectedRoute from "./components/ProtectedRoute";
import Billing from "./routes/Billing";
import AddressAndAccountContextProvider from "./components/AddressAndAccountProvider/AddressAndAccountProvider";
import SubscribeToISP from "./routes/SubscribeToISP";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {
  IconDefinition,
  IconPrefix,
  IconPack,
} from "@fortawesome/free-solid-svg-icons";
import CreateAccount from "./routes/CreateAccount/CreateAccount";
import ForgotEmail from "./routes/ForgotEmail";
import Dashboard from "./routes/Dashboard";
import Services from "./routes/Services";
import Plans from "./routes/Plans";
import UserProfile from "./routes/Profile/UserProfile";
import HelpCenter from "./routes/HelpCenter";
import FaqPage from "./routes/HelpCenter/components/Faq/FaqPage";
import DeviceGuidePage from "./routes/HelpCenter/components/DeviceGuide/DeviceGuidePage";
import DeviceTroubleshooting from "./routes/HelpCenter/components/DeviceGuide/DeviceTroubleshootingPage";
import ServiceProvidersPage from "./routes/HelpCenter/components/ServiceProviders/ServiceProvidersPage";
import BillingIssuesPage from "./routes/HelpCenter/components/BillingIssues/BillingIssuesPage";
import { getThemePath } from "./hooks/useGetThemePath";
import SubscriberPortalConfigurationContextProvider from "./context/SubscriberPortalConfigurations";

type IconDefinitionOrPack = IconDefinition | IconPack;

interface ImportedIcons {
  [key: string]: IconPrefix | IconDefinitionOrPack;
}

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => (Icons as ImportedIcons)[icon]);

library.add(...(iconList as IconDefinitionOrPack[]));

const themePath = getThemePath();
const colors = theme[themePath].colors;

const materialUITheme = createTheme({
  typography: {
    fontFamily: theme.fontFamily,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 845,
      lg: 1220,
      xl: 1400,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderBottom: "2px solid #C4C4C4",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: "16px",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          color: "#686868",
          fontSize: "16px",
          marginBottom: "16px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
        },
        head: {
          color: colors.table.primary,
          fontWeight: "600",
          fontSize: "16px",
          backgroundColor: colors.table.background,
          borderBottom: `1px solid ${colors.table.border}`,
          "&:first-child": {
            borderTopLeftRadius: "10px",
          },
          "&:last-child": {
            borderTopRightRadius: "10px",
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(odd)": {
            backgroundColor: colors.white,
          },
          "&:nth-of-type(even)": {
            backgroundColor: colors.table.evenCell,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "4px 8px",
          margin: "2px 8px",
          borderRadius: "8px",
          "&.Mui-selected": {
            backgroundColor: colors.menu.item.selectedBG,
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: colors.menu.item.hoverBG,
            },
          },
          "&.Mui-focusVisible": {
            backgroundColor: colors.menu.item.focusVisibleBG,
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: colors.textPrimary.heading,
        },
      },
    },
  },
});

export const routes = {
  login: {
    name: "login",
    path: "/",
    element: <Login />,
    hasHeader: false,
  },
  createAccount: {
    name: "create-account",
    path: "/create-account",
    element: <CreateAccount />,
    hasHeader: false,
  },
  forgotPassword: {
    name: "forgot-password",
    path: "/forgot-password",
    element: <ForgotPassword />,
    hasHeader: false,
  },
  forgotEmail: {
    name: "forgot-email",
    path: "/forgot-email",
    element: <ForgotEmail />,
    hasHeader: false,
  },
  updateAccount: {
    name: "update-account",
    path: "/update-account",
    element: (
      <ProtectedRoute>
        <AccountSettings />
      </ProtectedRoute>
    ),
    hasHeader: true,
  },
  subscribeToISP: {
    name: "subscribeToISP",
    path: "/subscribe/:serviceId/:planId",
    element: (
      <ProtectedRoute>
        <SubscribeToISP />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  dashboard: {
    name: "dashboard",
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  billing: {
    name: "billing",
    path: "/billing",
    element: (
      <ProtectedRoute>
        <Billing />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  services: {
    name: "services",
    path: "/services",
    element: (
      <ProtectedRoute>
        <Services />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  myServices: {
    name: "my-services",
    path: "/services?tab=My%20Services",
    element: (
      <ProtectedRoute>
        <Services />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  plans: {
    name: "plans",
    path: "/services/:id/plans",
    element: (
      <ProtectedRoute>
        <Plans />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },

  profile: {
    name: "profile",
    path: "/profile",
    element: (
      <ProtectedRoute>
        <UserProfile />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  help: {
    name: "help",
    path: "/help",
    element: (
      <ProtectedRoute>
        <HelpCenter />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  helpService: {
    name: "help-service",
    path: "/help/service",
    element: (
      <ProtectedRoute>
        <ServiceProvidersPage />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  helpDevice: {
    name: "help-device",
    path: "/help/device",
    element: (
      <ProtectedRoute>
        <DeviceGuidePage />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  helpTroubleshoot: {
    name: "help-troubleshoot",
    path: "/help/troubleshoot",
    element: (
      <ProtectedRoute>
        <DeviceTroubleshooting />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  helpBilling: {
    name: "help-billing",
    path: "/help/billing",
    element: (
      <ProtectedRoute>
        <BillingIssuesPage />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  helpFaq: {
    name: "help-faq",
    path: "/help/faq",
    element: (
      <ProtectedRoute>
        <FaqPage />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
};

const App = () => {
  return (
    <ThemeProvider theme={materialUITheme}>
      <GlobalStyles styles={globalStyle} />
      <ToastContainer />
      <AuthProvider>
        <AddressAndAccountContextProvider>
          <SubscriberPortalConfigurationContextProvider>
            <BillingProvider>
              <Routes>
                {Object.values(routes).map(
                  ({ name, path, element, hasHeader }) => (
                    <Route
                      key={name}
                      path={path}
                      element={
                        hasHeader ? (
                          <>
                            <Header />
                            {element}
                          </>
                        ) : (
                          element
                        )
                      }
                    />
                  )
                )}
              </Routes>
            </BillingProvider>
          </SubscriberPortalConfigurationContextProvider>
        </AddressAndAccountContextProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
