import { useContext, useState } from "react";
import {
  Typography,
  Stack,
  Rating,
  Box,
  Fade,
  IconButton,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Review } from "../../api/interfaces/Review";
import { theme } from "../../theme";
import { UserProfileIcon } from "../../assets/icons";
import useViewport from "../../hooks/useViewport";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import getREMFromPX from "../../utils/getREMFromPX";
import Button from "../../components/Button";
import { useCreateReview } from "../../hooks/useCreateReview";
import useGetUserId from "../../hooks/useGetUserId";
import useGetThemePath from "../../hooks/useGetThemePath";

interface ReviewModalProps {
  open: boolean;
  closeModal: () => void;
  review?: Review;
  planName: string;
  historyId: string;
}

const ReviewModal = ({
  open,
  review,
  planName,
  historyId,
  closeModal,
}: ReviewModalProps) => {
  const { userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { isMobile } = useViewport();
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const [rating, setRating] = useState(review?.rating ?? 0);
  const [comment, setComment] = useState(review?.comment);

  const { mutateAsync: mutateReview } = useCreateReview(useGetUserId(), {
    onError: () => {
      console.log("Failed");
    },
    onSuccess: () => {
      window.location.reload();
      closeModal();
    },
  });

  const modalStyles = {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 15.5px",
    },
    paper: {
      backgroundColor: colors.white || "#ffffff",
      flexGrow: 0,
      objectFit: "contain",
      borderRadius: "20px",
      outline: "none",
      boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.25)",
      position: "relative",
      overflow: "auto",
      maxHeight: "90%",
    },
    closeButton: {
      position: "absolute",
      top: 10,
      right: 10,
      color: colors.textPrimary.headingAlt,
    },
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      closeAfterTransition
      sx={modalStyles.modal}
    >
      <Fade in={open}>
        <Box
          sx={{
            ...modalStyles.paper,
            padding: !isMobile ? "48px 60px" : "16px",
          }}
        >
          <IconButton sx={modalStyles.closeButton} onClick={closeModal}>
            <CloseIcon
              sx={{ fontWeight: "bolder" }}
              fontSize="large"
              fill={colors.icons.default.fillColor}
            />
          </IconButton>
          <Box>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: "bold",
                color: colors.textPrimary.heading,
              }}
            >
              {planName}
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: "center",
                paddingTop: getREMFromPX(35),
                paddingBottom: getREMFromPX(15),
              }}
            >
              <UserProfileIcon />
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "left",
                  color: colors.textPrimary.primary,
                }}
              >
                {userInfo?.data?.user?.Contact?.first_name}{" "}
                {userInfo?.data?.user?.Contact?.last_name}
              </Typography>
            </Stack>
            <Stack sx={{ paddingBottom: getREMFromPX(30) }}>
              <Rating
                size="large"
                value={rating}
                style={{
                  color: colors.notification.warning.primary,
                  fontSize: 40,
                }}
                onChange={(e, val) => setRating(val ?? 0)}
              />
            </Stack>
            <textarea
              style={{
                resize: "none",
                width: getREMFromPX(440),
                maxWidth: getREMFromPX(440),
                minHeight: getREMFromPX(291),
                padding: getREMFromPX(20),
                borderRadius: "24px",
                borderStyle: "solid",
                borderWidth: "2px",
                borderColor: colors.textPrimary.primary,
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(16),
                fontWeight: "500",
                lineHeight: getREMFromPX(25),
                textAlign: "left",
                marginBottom: getREMFromPX(30),
                color: colors.form.primary,
              }}
              placeholder="Share details of your experience with this service"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              data-cy="review-modal-textarea"
            />
            <Stack
              sx={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Button
                text="Cancel"
                mode="disabled"
                onClick={() => closeModal()}
                sx={{ padding: "20px 55px" }}
              />
              <Button
                disabled={!rating}
                text="Post"
                mode="default"
                data-testid="submit_button"
                sx={{ padding: "20px 67px" }}
                onClick={() => {
                  if (rating) {
                    mutateReview({
                      id: review?.id,
                      rating,
                      comment: comment ?? "",
                      subscriptionhistory_id: historyId,
                    });
                  }
                }}
              />
            </Stack>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
export default ReviewModal;
