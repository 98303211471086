import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { Stack, Typography } from "@mui/material";
import { Notification } from "../../api/interfaces/Notification";
import { useClearNotification } from "../../hooks/useClearNotification";
import sendErrorToast from "../../utils/sendErrorToast";
import useGetThemePath from "../../hooks/useGetThemePath";

type PropTypes = {
  notifications: Notification[] | undefined;
  closeModal: Function;
  setContent: Function;
};

export const ClearNotifications = ({
  notifications,
  closeModal,
  setContent,
}: PropTypes) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { mutateAsync: clearNotification } = useClearNotification();

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(20),
          fontWeight: "bold",
          fontStyle: "normal",
          lineHeight: getREMFromPX(40),
          letterSpacing: 0,
          textAlign: "center",
          color: "black",
        }}
      >
        Are you sure you want to clear notifications?
      </Typography>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: getREMFromPX(20),
        }}
      >
        <Typography
          onClick={() => setContent("notifications")}
          sx={{
            cursor: "pointer",
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(16),
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "right",
            color: colors.textPrimary.headingAlt,
          }}
        >
          Cancel
        </Typography>
        <Typography
          onClick={async () => {
            const promises = notifications?.map?.((n) => clearNotification(n));
            Promise.all([promises])
              .then(() => closeModal())
              .catch(() =>
                sendErrorToast(
                  "There was an unexpected error while clearing notifications"
                )
              );
          }}
          sx={{
            cursor: "pointer",
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(16),
            fontWeight: "bold",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "right",
            color: colors.textPrimary.headingAlt,
          }}
        >
          Clear All
        </Typography>
      </Stack>
    </>
  );
};
