import React, { useEffect, useMemo, useState } from "react";
import { ProviderInfo } from "../../api/interfaces/Provider";
import { SingleUser } from "../../api/interfaces/User";
import { useGetProvider } from "../../hooks/useGetProvider";
import { useGetUser } from "../../hooks/useGetUser";
import useGetUserId from "../../hooks/useGetUserId";
import sendErrorToast from "../../utils/sendErrorToast";
import { MenuItemType } from "../Layout/components/AppBar";
import { Account as AccountType } from "../../api/interfaces/Account";
import { Device, Device as DeviceType } from "../../api/interfaces/Device";

type AccountAndAddressType = MenuItemType & {
  accountType: string;
  accountId: string;
  deviceId: string;
};

export type AddressAndAccountContextType = {
  userInfo?: SingleUser;
  setUserInfo: Function;
  refetchUser: Function;
  provider?: ProviderInfo;
  addresses: AccountAndAddressType[];
  selectedAddress: string;
  isLoading: boolean;
  selectedAccount: string;
  selectedAccountId: string;
  selectedDeviceId: string;
  currentAccount: AccountType;
  currentDevice: DeviceType;
  devices: DeviceType[];
  setCurrentDevice: (device: DeviceType) => void;
  setCurrentAccount: (acccount: AccountType) => void;
};

export const AddressAndAccountContext = React.createContext<
  AddressAndAccountContextType | {}
>({});

const AddressAndAccountContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const userId = useGetUserId();

  const {
    data: userInfoData,
    isLoading: isUserLoading,
    refetch: refetchUser,
  } = useGetUser(userId, {
    enabled: !!userId,
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast("Sorry, there was a problem retrieving your user info"),
  });

  const { data: provider, isLoading: isProviderLoading } = useGetProvider({
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    onError: () =>
      sendErrorToast("Sorry, there was a problem retrieving the city info"),
  });

  const [userInfo, setUserInfo] = useState<SingleUser>();
  const [addresses, setAddresses] = useState<AccountAndAddressType[]>([]);
  const [selectedAddress, setSelectedAddress] = useState("");

  const [currentAccount, setCurrentAccount] = useState<AccountType>();
  const [currentDevice, setCurrentDevice] = useState<DeviceType>();
  const [devices, setDevices] = useState<DeviceType[]>();

  const isLoading = isUserLoading || isProviderLoading;

  useEffect(() => {
    setUserInfo(userInfoData);
  }, [userInfoData]);

  const value = useMemo(
    () => ({
      userInfo,
      setUserInfo,
      refetchUser,
      provider,
      selectedAccountId: currentAccount?.id, // TODO: Update code to not use selectedAccountId
      addresses,
      selectedAddress,
      isLoading,
      selectedAccount: currentAccount,
      selectedDeviceId: currentDevice?.id,

      currentAccount,
      currentDevice,
      devices,
      setCurrentDevice: (device: DeviceType) => {
        if (currentAccount) {
          localStorage.setItem(`${currentAccount.id}-device`, device.id);
          setCurrentDevice(device);
        }
      },
      setCurrentAccount: (account: AccountType) => {
        localStorage.setItem("currentAccountId", account.id);
        setCurrentAccount(account);
      },
    }),
    [
      addresses,
      isLoading,
      provider,
      selectedAddress,
      userInfo,
      refetchUser,
      currentAccount,
      currentDevice,
      devices,
    ]
  );

  function sortDevicesInAlphabeticalOrder(devices: Device[] | undefined) {
    // Filtering out devices without physical locations to avoid crashing while sorting the devices.
    const filteredDevices = devices?.filter(
      (device) => device.Physicallocation
    );

    // Adding ! assertion as typescript doesn't know that physical locations in filteredDevices is not undefined
    return filteredDevices?.sort((a, b) =>
      a.Physicallocation!.house_number.localeCompare(
        b.Physicallocation!.house_number
      )
    );
  }

  useEffect(() => {
    if (userInfo) {
      const availableAddresses = userInfo.data?.user?.Accounts?.map((account) =>
        account?.Devices?.map((device) => ({
          accountId: account.id,
          accountType: account.type,
          deviceId: device.id,
          label: `${device.Physicallocation?.house_number} ${device.Physicallocation?.street}`,
          onClick: () => {
            localStorage.setItem("selectedAccount", device.id);
            setSelectedAddress(
              `${device.Physicallocation?.house_number} ${device.Physicallocation?.street}`
            );
          },
        }))
      ).flat() as unknown as AccountAndAddressType[];

      // if account was previously stored use it, otherwise use the first account for the user
      const currentAccountId = localStorage.getItem("currentAccountId");

      let tempAccount;
      if (currentAccountId) {
        tempAccount = userInfo?.data?.user?.Accounts?.find(
          (account) => account.id === currentAccountId
        );
      }

      if (!tempAccount) {
        tempAccount = userInfo?.data?.user?.Accounts?.[0];
      }

      setCurrentAccount(tempAccount);
      setDevices(sortDevicesInAlphabeticalOrder(tempAccount?.Devices));

      if (availableAddresses) {
        setAddresses(availableAddresses);
        const savedDeviceId = localStorage.getItem("selectedAccount");
        const previouslySelectedAddress = availableAddresses.find(
          (address) => address.deviceId === savedDeviceId
        );
        if (previouslySelectedAddress) {
          setSelectedAddress(previouslySelectedAddress.label);
          return;
        }

        const selAddress = availableAddresses[0]?.label || "";
        const selDeviceId = availableAddresses[0]?.deviceId || "";
        setSelectedAddress(selAddress);
        localStorage.setItem("selectedAccount", selDeviceId);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (currentAccount) {
      // if device was previously stored use it, otherwise use the first device for the account
      const currentDeviceId = localStorage.getItem(
        `${currentAccount.id}-device`
      );

      let tempDevice;
      if (currentDeviceId) {
        tempDevice = currentAccount.Devices?.find(
          (device) => device.id === currentDeviceId
        );
      }

      if (!tempDevice) {
        tempDevice = currentAccount.Devices?.[0];
      }
      setDevices(sortDevicesInAlphabeticalOrder(currentAccount.Devices));
      setCurrentDevice(tempDevice);
    }
  }, [currentAccount]);

  useEffect(() => {
    if (provider) {
      document.title = provider.data.provider.name;
    }
  }, [provider]);

  return (
    <AddressAndAccountContext.Provider value={value}>
      {children}
    </AddressAndAccountContext.Provider>
  );
};
export default AddressAndAccountContextProvider;
