import React, { useState } from "react";
import { Box, ClickAwayListener } from "@mui/material";
import Input from "../../components/Input";
import ListSelect from "../../components/ListSelect/ListSelect";
import SelectButton from "../../components/SelectButton/SelectButton";
import FiltersMenu, { menuOptions } from "./FiltersMenu";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { SortFilterLabel } from "./interfaces";
import { theme } from "../../theme";
import { getThemePath } from "../../hooks/useGetThemePath";

interface FiltersMobileProps {
  options: Array<{ label: string; isActive: boolean }>;
  handleChangeActiveTab: (v: number) => void;
  setSortFilter: (v: SortFilterLabel | null) => void;
  sortFilter: SortFilterLabel | null;
  handleInputChange?: (val: string) => void;
  isMyTabsActive: boolean;
}

const themePath = getThemePath();
const colors = theme[themePath].colors;

const StyledInput = styled("input")`
  width: 147px;
  height: 24px;
  padding: 11px;
  border-radius: 23.9px;
  font-size: 12px;
  border: solid 2px #e4edea;
  background-repeat: no-repeat;
  background-position: 10px center;
  font-family: "Inter";
  &:hover {
    outline: 2px solid ${colors.links.teal};
  }
  &:focus {
    outline: 2px solid ${colors.links.teal};
  }

  @media (max-width: 1300px) {
    width: 100%;
    marginright: 32px;
  }
`;

const FiltersMobile = ({
  options,
  handleChangeActiveTab,
  sortFilter,
  setSortFilter,
  handleInputChange = () => {},
  isMyTabsActive,
}: FiltersMobileProps) => {
  const [showFiltersMenu, setShowFiltersMenu] = useState(false);
  const [sortByOptions, setSortByOptions] = useState(menuOptions);

  return (
    <>
      {isMyTabsActive && (
        <Box marginBottom="16px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ListSelect
              options={options}
              handleChangeActiveTab={handleChangeActiveTab}
            />
            <Input size="small" placeholder="Private Code" />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop="8px"
          >
            <Box sx={{ position: "relative" }}>
              <SearchIcon
                style={{
                  position: "absolute",
                  left: "5px",
                  top: "2px",
                  color: colors.links.teal,
                }}
                fill={colors.icons.default.fillColor}
              />
              <StyledInput
                style={{ marginRight: "28px", paddingLeft: "30px" }}
                onChange={(val) =>
                  handleInputChange(val.target.value as string)
                }
              />
            </Box>
            <ClickAwayListener onClickAway={() => setShowFiltersMenu(false)}>
              <Box height="24px">
                <SelectButton
                  content="Sort by"
                  mode="default"
                  onClick={() => setShowFiltersMenu(!showFiltersMenu)}
                  sx={{
                    width: "110.6px",
                    height: "25px",
                    borderRadius: "12.2px",
                    fontSize: "8px",
                    padding: "0 0 0 5px",
                  }}
                />
                {showFiltersMenu ? (
                  <FiltersMenu
                    setSortFilter={(arg) => {
                      setShowFiltersMenu(false);
                      setSortFilter(arg);
                    }}
                    sortFilter={sortFilter}
                    sortByOptions={sortByOptions}
                    setSortByOptions={(opts) => setSortByOptions(opts)}
                  />
                ) : (
                  <> </>
                )}
              </Box>
            </ClickAwayListener>
          </Box>
        </Box>
      )}
    </>
  );
};

export default FiltersMobile;
