import {
  Box,
  Modal,
  IconButton,
  Typography,
  Link,
  Stack,
  Button,
} from "@mui/material";
import { theme } from "../../../theme";
import NewButton from "../../../components/Button";
import CloseIcon from "@mui/icons-material/Close";
import useGetThemePath from "../../../hooks/useGetThemePath";
import { OverviewDataModel } from "../../../components/AllServices/AllServices";
import { useState } from "react";
import getREMFromPX from "../../../utils/getREMFromPX";
import { useGetPlanReviews } from "../../../hooks/useGetPlanReviews";
import { Review } from "../../../api/interfaces/Review";
import { renderStars } from "../utils/getStars";
import { convertToDecimalIfNeeded } from "../utils/convertToDecimal";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LanguageIcon from "@mui/icons-material/Language";
import useViewport from "../../../hooks/useViewport";

const tabOptions = [
  {
    label: "Overview",
    isActive: true,
  },
  {
    label: "Reviews",
    isActive: false,
  },
  {
    label: "Provider Info",
    isActive: false,
  },
];

const WidgetRow = ({ label, value }: { label: string; value: string }) => {
  const { isMobile } = useViewport();
  return (
    <Stack direction="row">
      <Typography
        flex={1}
        style={{
          color: "black",
          fontSize: getREMFromPX(isMobile ? 10 : 20),
          fontFamily: "Montserrat",
          fontWeight: "700",
          wordWrap: "break-word",
        }}
      >
        {label}:
      </Typography>
      <Typography
        flex={1}
        style={{
          color: "#253331",
          fontSize: getREMFromPX(isMobile ? 10 : 20),
          fontFamily: "Montserrat",
          fontWeight: "500",
          wordWrap: "break-word",
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

const Widget = ({
  children,
  padding,
}: {
  children: React.ReactElement | React.ReactElement[];
  padding?: string;
}) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  return (
    <Stack
      flex={1}
      bgcolor={colors.hover.primary}
      sx={{ borderRadius: "8px" }}
      padding={padding ?? `${getREMFromPX(30)} ${getREMFromPX(15)}`}
    >
      {children}
    </Stack>
  );
};

const OverviewData = ({
  modalOverviewData,
}: {
  setOpenWorkflowSubscriptionModal: Function;
  modalOverviewData: OverviewDataModel;
  handleClose: Function;
}) => {
  const { isMobile } = useViewport();
  return (
    <Stack spacing={2}>
      <Widget>
        <Stack direction="row" spacing={3} height={getREMFromPX(110)}>
          <Typography
            style={{
              textAlign: "right",
              color: "black",
              fontSize: getREMFromPX(isMobile ? 12 : 20),
              fontFamily: "Montserrat",
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            Description:
          </Typography>
          <Typography
            style={{
              width: "100%",
              height: "100%",
              color: "black",
              fontSize: getREMFromPX(isMobile ? 8 : 16),
              fontFamily: "Montserrat",
              fontWeight: "500",
              paddingTop: getREMFromPX(5),
              wordWrap: "break-word",
            }}
          >
            {modalOverviewData.description}
          </Typography>
        </Stack>
      </Widget>
      <Stack direction="row" spacing={2}>
        <Widget>
          <Stack spacing={3}>
            <WidgetRow
              label="Up"
              value={`${modalOverviewData.fields.Up}mb/s`}
            />
            <WidgetRow
              label="Down"
              value={`${modalOverviewData.fields.Down}mb/s`}
            />
          </Stack>
        </Widget>
        <Widget padding={getREMFromPX(8)}>
          <Stack flex={1} justifyContent="center" alignItems="center">
            <Typography
              style={{
                color: "#253331",
                fontSize: getREMFromPX(isMobile ? 16 : 32),
                fontFamily: "Montserrat",
                fontWeight: "700",
              }}
            >
              ${modalOverviewData.fields.Price}/month
            </Typography>
          </Stack>
        </Widget>
      </Stack>
    </Stack>
  );
};

const ReviewsBox = ({
  reviews,
  modalOverviewData,
}: {
  setOpenWorkflowSubscriptionModal: Function;
  reviews: Review[] | undefined;
  modalOverviewData: OverviewDataModel;
  handleClose: Function;
}) => {
  const starsReviewAverage = convertToDecimalIfNeeded(
    Number(modalOverviewData?.total_reviews) !== 0
      ? Number(modalOverviewData?.ratings_sum) /
          Number(modalOverviewData?.total_reviews)
      : 0
  );

  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  return (
    <Box paddingTop={getREMFromPX(16)}>
      <Box
        marginBottom="16px"
        style={{ overflowY: "scroll", height: getREMFromPX(320) }}
      >
        <Box display="flex" alignItems="center">
          <Typography
            fontFamily="Montserrat"
            fontSize={getREMFromPX(68)}
            color="#02575c"
          >
            {starsReviewAverage}
          </Typography>
          <Box marginTop="20px">
            {renderStars(starsReviewAverage, 30, "1.5px")}
            <Typography
              fontFamily="Montserrat"
              fontSize={getREMFromPX(14)}
              color="#6d6d6d"
            >
              {modalOverviewData?.total_reviews} reviews
            </Typography>
          </Box>
        </Box>
        <Box position={"relative"}>
          <Box maxHeight="300px" overflow="auto">
            {reviews?.map((review, idx) => (
              <Box marginTop={idx === 0 ? "48px" : "16px"} key={idx}>
                <Box display="flex" alignItems="center" gap="17.5px">
                  <AccountCircleIcon
                    sx={{ height: "40px", width: "40px" }}
                    fill={colors.icons.default.fillColor}
                  />
                  <Typography
                    fontFamily="Montserrat"
                    fontSize={getREMFromPX(20)}
                    fontWeight="bold"
                    color="#02575c"
                  >
                    {review.User?.Contact?.first_name}{" "}
                    {review.User?.Contact?.last_name?.[0]}
                  </Typography>
                </Box>
                <Typography marginTop="8px">
                  {renderStars(review.rating as unknown as string, 25, "0px")}
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontSize={getREMFromPX(16)}
                  fontWeight={500}
                  color={colors.textPrimary.heading}
                  marginTop="8px"
                >
                  {review.comment}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const ProviderInfo = ({
  modalOverviewData,
}: {
  setOpenWorkflowSubscriptionModal: Function;
  modalOverviewData: OverviewDataModel;
  handleClose: Function;
}) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingTop={getREMFromPX(16)}
      flexDirection="column"
    >
      <Box
        display="flex"
        flexDirection="column"
        bgcolor={colors.hover.primary}
        padding="16px"
        sx={{
          borderRadius: "8px",
          minHeight: "250px",
          width: "100%",
        }}
      >
        <Typography
          fontSize={getREMFromPX(20)}
          fontFamily="Montserrat"
          color={colors.textPrimary.heading}
          fontWeight="bold"
          marginBottom="20px"
        >
          Contact Info:
        </Typography>
        {modalOverviewData.support_url && (
          <>
            <Typography
              fontSize={getREMFromPX(20)}
              fontFamily="Montserrat"
              color={colors.textPrimary.heading}
              fontWeight="bold"
              gap="25px"
              display="flex"
              marginBottom="20px"
              alignItems="center"
            >
              <LanguageIcon
                sx={{
                  width: "35px",
                  height: "35px",
                }}
                fill={colors.icons.default.fillColor}
              />
              <Link
                href={modalOverviewData.support_url}
                sx={{
                  color: colors.textPrimary.heading,
                  textDecoration: "none",
                }}
              >
                {modalOverviewData.support_url}
              </Link>
            </Typography>
          </>
        )}
        {modalOverviewData.email && (
          <>
            <Typography
              fontSize={getREMFromPX(20)}
              fontFamily="Montserrat"
              color={colors.textPrimary.heading}
              fontWeight="bold"
              gap="25px"
              display="flex"
              alignItems="center"
              marginBottom="20px"
            >
              <EmailIcon
                sx={{
                  width: "35px",
                  height: "35px",
                }}
                fill={colors.icons.default.fillColor}
              />
              <Link
                href={`mailto:${modalOverviewData.email}`}
                sx={{
                  color: colors.textPrimary.heading,
                  textDecoration: "none",
                }}
              >
                {modalOverviewData.email}
              </Link>
            </Typography>
          </>
        )}
        {modalOverviewData.phone && (
          <>
            <Typography
              fontSize={getREMFromPX(20)}
              fontFamily="Montserrat"
              color={colors.textPrimary.heading}
              fontWeight="bold"
              gap="25px"
              display="flex"
              alignItems="center"
            >
              <LocalPhoneIcon
                sx={{
                  width: "35px",
                  height: "35px",
                }}
                fill={colors.icons.default.fillColor}
              />
              <Link
                href={`tel:${modalOverviewData.phone}`}
                sx={{
                  color: colors.textPrimary.heading,
                  textDecoration: "none",
                }}
              >
                {modalOverviewData.phone}
              </Link>
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const SubscribeModal = ({
  open,
  handleClose,
  modalOverviewData,
  isUserRatingEnabled,
  setOpenWorkflowSubscriptionModal,
}: {
  open: boolean;
  isUserRatingEnabled: boolean;
  handleClose: Function;
  modalOverviewData: OverviewDataModel;
  setOpenWorkflowSubscriptionModal: Function;
}) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const [tabs, setTabs] = useState(() => tabOptions);
  const { data } = useGetPlanReviews(modalOverviewData.planId, {
    enabled: !!modalOverviewData.planId,
  });

  const getCurrentTabInformation = () => {
    const currentTab = tabs.find((t) => t.isActive === true);

    switch (currentTab?.label) {
      case "Overview":
        return (
          <OverviewData
            setOpenWorkflowSubscriptionModal={() =>
              setOpenWorkflowSubscriptionModal(true)
            }
            modalOverviewData={modalOverviewData}
            handleClose={handleClose}
          />
        );

      case "Reviews":
        return (
          <ReviewsBox
            setOpenWorkflowSubscriptionModal={() =>
              setOpenWorkflowSubscriptionModal(true)
            }
            reviews={data?.data.review}
            modalOverviewData={modalOverviewData}
            handleClose={handleClose}
          />
        );

      case "Provider Info":
        return (
          <ProviderInfo
            setOpenWorkflowSubscriptionModal={() =>
              setOpenWorkflowSubscriptionModal(true)
            }
            modalOverviewData={modalOverviewData}
            handleClose={handleClose}
          />
        );

      default:
        return <></>;
    }
  };

  const handleChangeActiveTab = (index: number) => {
    const changedActiveTabs = [...tabs].map((v, i: number) => ({
      ...v,
      isActive: i === index,
    }));
    setTabs(changedActiveTabs);
  };

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box
        maxWidth={getREMFromPX(536)}
        height={getREMFromPX(736)}
        borderRadius="8px"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: theme[themePath].colors.white,
          padding: "48px 3px 7px 2px",
          width: "100%",
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        <IconButton
          style={{ position: "absolute", top: "3px", right: "3px" }}
          onClick={() => handleClose()}
          sx={{
            color: colors.links.teal,
          }}
        >
          <CloseIcon fill={colors.icons.default.fillColor} />
        </IconButton>
        <Typography
          fontSize={getREMFromPX(16)}
          fontFamily="Montserrat"
          color={colors.form.primary}
          fontWeight={600}
          textAlign="center"
          marginBottom="16px"
          textTransform="uppercase"
        >
          {modalOverviewData.name}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/provider/${modalOverviewData.provider_id}.png`}
            alt=""
            role="presentation"
            width="207"
            height="63"
            style={{
              objectFit: "cover",
            }}
          />
        </Box>
        <Typography
          fontSize={getREMFromPX(20)}
          fontFamily="Montserrat"
          color={colors.textPrimary.heading}
          fontWeight={600}
          textAlign="center"
          marginTop="16px"
          marginBottom="40px"
        >
          {modalOverviewData.planName}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          sx={{ width: "100%", marginBottom: "8px" }}
        >
          {tabs.map(({ label, isActive }, index) => {
            if (!isUserRatingEnabled && label === "Reviews") {
              return null;
            }
            return (
              <Button
                variant="text"
                key={index}
                onClick={() => handleChangeActiveTab(index)}
                sx={{
                  fontSize: getREMFromPX(16),
                  fontWeight: isActive ? "bold" : 600,
                  fontFamily: "Montserrat",
                  color: isActive
                    ? colors.textPrimary.heading
                    : colors.form.primary,
                  textTransform: "uppercase",
                  "&:hover": {
                    color: colors.form.primary,
                    backgroundColor: "transparent",
                  },
                  cursor: "pointer",
                  paddingLeft: 0,
                  paddingRight: 0,
                  height: "24px",
                  borderRadius: "4px",
                  marginLeft: "16px",
                  background: "white",
                  padding: "14px 10px",
                  border: isActive
                    ? `2px solid ${colors.textPrimary.heading}`
                    : `2px solid tranasperant`,
                }}
                data-cy={`serrvice-info-tab-${label.replace(/\s+/g, "")}`}
                aria-pressed={isActive}
              >
                {label}
              </Button>
            );
          })}
        </Box>
        <Box
          height={getREMFromPX(350)}
          padding={`${getREMFromPX(16)} ${getREMFromPX(32)}`}
        >
          {getCurrentTabInformation()}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            padding: `${getREMFromPX(16)} ${getREMFromPX(32)}`,
          }}
        >
          <NewButton
            mode="default"
            text="Subscribe"
            onClick={() => {
              handleClose();
              setOpenWorkflowSubscriptionModal(true);
            }}
            sx={{
              width: `${getREMFromPX(218)}`,
              height: `${getREMFromPX(56)}`,
              borderRadius: `${getREMFromPX(15)}`,
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default SubscribeModal;
