import { useState, useContext } from "react";
import {
  Box,
  Checkbox,
  Stack,
  Typography,
  checkboxClasses,
} from "@mui/material";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import Button from "../Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useGetPaymentMethod } from "../../hooks/useGetPaymentMethod";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import PaymentMethod from "../../components/PaymentMethod";
import { BillingContext, BillingType } from "../../components/BillingProvider";
import useGetThemePath from "../../hooks/useGetThemePath";

const SummaryCardStep = ({
  paymentType,
  goBack,
  close,
}: {
  paymentType: string;
  goBack: () => void;
  close: () => void;
}) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const { selectedAccountId } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { prices } = useContext(BillingContext) as BillingType;

  const { data: paymentMethod } = useGetPaymentMethod(selectedAccountId);

  const [acknowledgeFee, setAcknowledgeFee] = useState(false);

  const details = paymentMethod?.data?.billing?.payment_method;
  const rows = [
    ...(details?.owner_name ? [`Name: ${details.owner_name}`] : []),
    ...(details?.routing_number ? [`Routing: ${details.routing_number}`] : []),
    ...(details?.last4 && paymentType === "ACH"
      ? [`Account: ${details.last4}`]
      : []),
    ...(details?.account_type ? [`Account type: ${details.account_type}`] : []),
    ...(details?.account_holder_type
      ? [`Account Holder type: ${details.account_holder_type}`]
      : []),

    ...(details?.last4 && details?.brand && paymentType === "Credit Card"
      ? [`${details.brand} ending in ${details.last4}`]
      : []),
  ];

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(36),
          fontWeight: 600,
          color: colors.dark,
          textAlign: "center",
          marginBottom: "50px",
        }}
      >
        {paymentType === "ACH" ? "ACH" : "Credit Card"} Payment
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <PaymentMethod {...paymentMethod?.data?.billing?.payment_method} />
        <Box>
          {rows.map((detailRow) => (
            <Typography
              key={detailRow}
              sx={{
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(20),
                fontWeight: 600,
                color: colors.dark,
                marginBottom: "24px",
              }}
            >
              {detailRow}
            </Typography>
          ))}
        </Box>
      </Box>
      <Box>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(16),
            fontWeight: 500,
            color: colors.dark,
            fontStyle: "italic",
            margin: "56.5px 0 56px 0",
            textAlign: "center",
          }}
        >
          Account to be charged automatically on the 1st of each month.
        </Typography>
      </Box>
      {paymentType === "Credit Card" && (
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            gap={getREMFromPX(theme.spacing * 4)}
          >
            <Checkbox
              checked={acknowledgeFee}
              onChange={() => setAcknowledgeFee((current) => !current)}
              sx={{
                transform: "scale(1.3)",
                [`&, &.${checkboxClasses.checked}`]: {
                  color: colors.textPrimary.headingAlt,
                },
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  borderRadius: "50px",
                },
              }}
            />

            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(20),
                fontWeight: 500,
                color: colors.dark,
                textAlign: "center",
              }}
            >
              I acknowledge that a $
              {prices?.cc_initial_processing_fee?.amount?.toFixed(2) ?? 1.5}{" "}
              processing fee will be added when using a credit card.
            </Typography>
          </Stack>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "53.4px",
        }}
      >
        <Box
          onClick={goBack}
          sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <ArrowBackIosIcon
            sx={{
              cursor: "pointer",
            }}
            fill={colors.icons.default.fillColor}
          />
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: getREMFromPX(16),
              fontWeight: "bold",
              color: colors.textPrimary.headingAlt,
              cursor: "pointer",
            }}
          >
            Back
          </Typography>
        </Box>
        <Button
          text="Ok"
          mode="default"
          data-testid="submit_button"
          sx={{ padding: "16px 67px" }}
          onClick={close}
          disabled={paymentType === "Credit Card" && !acknowledgeFee}
        />
      </Box>
    </>
  );
};

export default SummaryCardStep;
