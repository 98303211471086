import { useState, useContext } from "react";
import CardWrapper from "../../../components/CardWrapper";
import { theme } from "../../../theme";
import getREMFromPX from "../../../utils/getREMFromPX";
import { Typography, Grid, Box, Stack } from "@mui/material";
import { ProfileSettingsModal } from "../../../components/CustomModal";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../components/AddressAndAccountProvider";
import DetailRow from "./DetailRow";
import DetailsModal from "./DetailsModal";
import useGetThemePath from "../../../hooks/useGetThemePath";

const UserDetails = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const contact = userInfo?.data?.user?.Contact;

  const [showModal, setShowModal] = useState(false);

  const editAction = (
    <Typography
      onClick={() => setShowModal(true)}
      sx={{
        cursor: "pointer",
        fontFamily: "Montserrat",
        fontSize: getREMFromPX(16),
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        color: colors.links.teal,
      }}
      data-cy="profile-mydetails-edit"
    >
      Edit
    </Typography>
  );

  const closeModal = () => {
    setShowModal(false);
  };

  const divider = (
    <Box
      sx={{
        height: getREMFromPX(1),
        width: "100%",
        backgroundColor: "rgba(126, 130, 129, 0.45)",
        margin: "30px 0px",
      }}
    />
  );

  const address1 = `${contact?.Location?.address1}`;
  const address2 = `${contact?.Location?.address2}`;
  const cityStateZip = `${contact?.Location?.city}, ${contact?.Location?.state} ${contact?.Location?.zip}`;

  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <ProfileSettingsModal open={showModal} onClose={closeModal}>
        <DetailsModal closeModal={closeModal} />
      </ProfileSettingsModal>
      <CardWrapper
        title="My Details"
        actions={editAction}
        sx={{ minHeight: getREMFromPX(167), padding: "10px 20px" }}
      >
        <Stack sx={{ padding: "30px 0px" }}>
          <DetailRow
            label="Name"
            value={`${contact?.first_name} ${contact?.last_name}`}
          />
          {divider}
          <DetailRow label="Phone Number" value={contact?.phone ?? ""} />
          {divider}
          <DetailRow
            label="Address"
            value={address1}
            value2={address2 ? address2 : cityStateZip}
            value3={address2 && cityStateZip}
          />
        </Stack>
      </CardWrapper>
    </Grid>
  );
};
export default UserDetails;
