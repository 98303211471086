import { useState } from "react";
import CardWrapper from "../../components/CardWrapper";

import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { Link } from "react-router-dom";
import { Stack, Typography, Grid, Box, Button } from "@mui/material";
import { routes } from "../../App";
import { ForwardArrowIcon } from "../../assets/icons";
import { LocationIcon, RouterIcon, RotateIcon } from "../../assets/icons";
import { Device as DeviceType } from "../../api/interfaces/Device";
import useArePortsLive from "../../hooks/useArePortsLive";
import { Port } from "../../api/interfaces/Port";
import SelectDevice from "../../components/SelectDevice";
import {
  ONTDeviceFront,
  ONTDeviceBack,
  VenoenDeviceFront,
  VenoenDeviceBack,
  AaeonDeviceFront,
  AaeonDeviceBack,
} from "../../assets/device";
import useViewport from "../../hooks/useViewport";

type DeviceStatusProps = {
  status: DeviceStatusType;
  isLive?: boolean;
};

type DeviceStatusType =
  | "ACTIVE"
  | "REPAIRED"
  | "INACTIVE"
  | "SUSPENDED"
  | "LOCKED"
  | "PAUSED"
  | "REPLACED"
  | "RMA"
  | "STANDBY"
  | "PENDING"
  | "PROVISION"
  | "PROVISIONING"
  | "PENDING_ACTIVATION";

const DeviceStatus = ({ status, isLive = false }: DeviceStatusProps) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { isMobile } = useViewport();
  let properties;

  switch (status) {
    case "INACTIVE":
    case "SUSPENDED":
    case "LOCKED":
    case "PAUSED":
    case "REPLACED":
    case "RMA":
      properties = {
        backgroundColor: colors.activeElementBackground,
        textColor: colors.form.primary,
        label: "Device is Disabled",
      };
      break;
    case "STANDBY":
    case "PENDING_ACTIVATION":
    case "PROVISIONING":
      properties = {
        backgroundColor: colors.activeElementBackground,
        textColor: colors.form.primary,
        label: "Pending Status",
      };
      break;
    case "ACTIVE":
    case "REPAIRED":
      if (isLive) {
        properties = {
          backgroundColor: colors.notification.success.accent,
          textColor: colors.textPrimary.heading,
          label: "Device Connected",
        };
      } else {
        properties = {
          backgroundColor: colors.notification.error.primary,
          textColor: colors.textSecondary.primary,
          label: "Device Needs Attention",
        };
      }
      break;
    default:
      properties = {
        backgroundColor: colors.notification.error.primary,
        textColor: colors.textSecondary.primary,
        label: "Device Needs Attention",
      };
  }

  return properties.label === "Device Needs Attention" ? (
    <Stack
      sx={{
        flexDirection: "row",
        maxWidth: getREMFromPX(468),
        width: {
          sm: "100%",
          xs: "auto",
        },
        backgroundColor: {
          sm: colors.white,
          xs: "transparent",
        },
        padding: {
          sm: "16px 24px",
          xs: 0,
        },
        borderRadius: getREMFromPX(30),
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(14),
          fontWeight: "600",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.textPrimary.heading,
          display: {
            sm: "block",
            xs: "none",
          },
        }}
      >
        We’re having trouble connecting to your device. Please click Help to
        learn more.
      </Typography>
      <Button
        href={routes.helpDevice.path}
        sx={{
          backgroundColor: colors.notification.error.primary,
          maxWidth: getREMFromPX(120),
          textTransform: "none",
          width: "100%",
          height: {
            sm: getREMFromPX(38),
            xs: "auto",
          },
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 19.2,
          marginLeft: getREMFromPX(8),
          cursor: "pointer",

          fontFamily: "Montserrat",
          fontSize: {
            sm: getREMFromPX(14),
            xs: 10,
          },
          fontWeight: "600",
          fontStyle: "normal",
          padding: "2px 12px",
          letterSpacing: 0,
          color: colors.notification.error.accent,
        }}
        aria-label="Help"
      >
        Help
      </Button>
    </Stack>
  ) : (
    <Box
      sx={{
        maxWidth: {
          md: getREMFromPX(294),
          sm: "none",
        },
        height: {
          md: getREMFromPX(48),
          sm: 14,
        },
        padding: {
          sm: 3,
          xs: "4px 16px",
        },
        borderRadius: 30,
        backgroundColor: properties.backgroundColor,
        display: "flex",
        width: {
          md: "100%",
          xs: "fit-content",
        },
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: {
            sm: getREMFromPX(14),
            xs: 10,
          },
          fontWeight: "bold",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "center",
          color: properties.textColor,
        }}
      >
        {isMobile ? "ON" : properties.label}
      </Typography>
    </Box>
  );
};

const MyDevice = ({ device }: { device: DeviceType }) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const wanPort = (device.Portinterfaces as Port[]).filter((port) => {
    return port.name === "WAN";
  });
  const { data } = useArePortsLive(wanPort);
  const isLive =
    data[0]?.data?.portinterface?.live?.link_state === "up" || false;

  const [showFront, setShowFront] = useState(true);

  const arrowAction = (
    <Link
      to={routes.helpDevice.path}
      aria-label="Device Info"
      data-cy="dashboard-my-device"
    >
      <ForwardArrowIcon />
    </Link>
  );

  const getDeviceFront = () => {
    switch (device.Product?.Manufacturer?.name) {
      case "Aaeon":
        return AaeonDeviceFront;
      case "Venoen":
        return VenoenDeviceFront;
      case "Kontron":
        return ONTDeviceFront;
      default:
        return AaeonDeviceFront;
    }
  };

  const getDeviceBack = () => {
    switch (device.Product?.Manufacturer?.name) {
      case "Aaeon":
        return AaeonDeviceBack;
      case "Venoen":
        return VenoenDeviceBack;
      case "Kontron":
        return ONTDeviceBack;
      default:
        return AaeonDeviceBack;
    }
  };

  const DeviceFront = getDeviceFront();
  const DeviceBack = getDeviceBack();
  const DeviceImage = showFront ? DeviceFront : DeviceBack;

  const ports = (device.Portinterfaces as Port[]).filter(
    (portinterface) =>
      portinterface.display === true &&
      (portinterface.type === "LAN" || portinterface.type === "WAN")
  );

  const { data: portCollection } = useArePortsLive(ports);

  if (!device) {
    return <>Loading...</>;
  }

  return (
    <Grid item xl={12}>
      <CardWrapper
        sx={{
          backgroundColor: isLive
            ? colors.white
            : colors.notification.error.accent,
        }}
        title="My Device"
        status="Paid"
        actions={arrowAction}
      >
        <Stack
          direction="row"
          spacing={getREMFromPX(theme.spacing * 2)}
          mt={getREMFromPX(20)}
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <LocationIcon fill={colors.icons.default.fillColor} />
          <SelectDevice
            sx={{ minWidth: "30%", height: "36px", borderRadius: "10px" }}
          />
        </Stack>
        <Box
          sx={{
            width: "100%",
            marginTop: getREMFromPX(25),
            textAlign: "center",
            height: {
              sm: 285,
              xs: 200,
            },
          }}
        >
          <DeviceImage
            ports={portCollection}
            style={{ height: "inherit", width: "70%" }}
          />
        </Box>
        <Stack
          onClick={() => setShowFront(!showFront)}
          sx={{
            marginTop: getREMFromPX(-30),
            width: "100%",
            maxWidth: getREMFromPX(550),
            paddingRight: getREMFromPX(30),
            alignSelf: "center",
            justifyContent: "flex-end",
            alignItems: "center",
            flexDirection: "row",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              letterSpacing: 0,
              marginRight: getREMFromPX(3),
              background: "#fff",
              border: `1px solid ${colors.buttons.outline.border}`,
              fontSize: 14,
              fontWeight: 500,
              color: colors.form.primary,
              textAlign: "center",
              borderRadius: "4px",
              width: "fit-content",
              marginLeft: "auto",
              padding: "2px 16px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Rotate
            <RotateIcon height={16} fill={colors.icons.default.fillColor} />
          </Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: {
              lg: "row",
              md: "column",
              xs: "row",
            },
            justifyContent: "space-between",
            alignItems: {
              lg: "flex-end",
              md: "flex-start",
              xs: "flex-end",
            },
            mt: "1rem",
          }}
        >
          <Box
            sx={{
              maxWidth: {
                sm: "200px",
                xs: "120px",
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: {
                  md: getREMFromPX(20),
                  xs: getREMFromPX(12),
                },
                fontWeight: "600",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "left",
                color: colors.textPrimary.heading,
                marginTop: getREMFromPX(15),
                marginBottom: {
                  lg: getREMFromPX(24),
                  md: getREMFromPX(12),
                },
              }}
            >
              Virtual Broadband Gateway
            </Typography>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 1,
                marginBottom: {
                  lg: 0,
                  md: "16px",
                },
              }}
            >
              <RouterIcon fill={colors.icons.default.fillColor} />
              <Typography
                sx={{
                  marginLeft: {
                    md: getREMFromPX(15),
                    xs: 1,
                  },
                  fontFamily: "Montserrat",
                  fontSize: {
                    md: getREMFromPX(16),
                    xs: getREMFromPX(8),
                  },
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  textAlign: "left",
                  color: colors.textPrimary.heading,
                }}
              >
                {device?.type} {device?.hardware_version}
              </Typography>
            </Stack>
          </Box>
          <DeviceStatus
            status={device.status as DeviceStatusType}
            isLive={isLive}
          />
        </Stack>
      </CardWrapper>
    </Grid>
  );
};
export default MyDevice;
