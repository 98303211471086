import { MouseEventHandler, ReactNode, useContext, useState } from "react";
import {
  Box,
  Hidden,
  Stack,
  Toolbar,
  Typography,
  styled,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import { NavBar } from "./NavBar";
import { routes as routes } from "../../App";
import { SwitchAccountsIcon, UserProfileIcon } from "../../assets/icons";
import {
  WaffleIcon,
  BillingIcon,
  ServicesIcon,
  HelpIcon,
} from "../../assets/icons";
import useMenuAnchor from "../../hooks/useMenuAnchor";
import MenuItem from "../../components/MenuItem";
import Menu from "../../components/Menu";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { AuthContext, AuthenticationContext } from "../AuthProvider";
import { useGetConfig } from "../../hooks/useGetConfig";
import EntryPointLink from "../EntryPointLink/EntryPointLink";
import useViewport from "../../hooks/useViewport";
import getREMFromPX from "../../utils/getREMFromPX";
import { Logout, PersonOutline } from "@mui/icons-material";
import React from "react";

export interface MenuItemsFields {
  title: string;
  icon: any;
  route: any;
}

const menuListProps = {
  "aria-labelledby": "profile_options_button",
};

const ParentContainer = styled("aside")<{ backgroundColor: string }>`
  background-color: ${(props) =>
    props.backgroundColor}; /* This is your fallback color */
  position: relative;
  min-height: 100vh;
`;

const LeftMenuGradient = styled(Stack)<{ backgroundColor: string }>`
  position: relative;
  height: 100%;
  background: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: row;
`;

export interface NavContainerProps<T> {
  title: string;
  headerContent?: ReactNode;
  children?: ReactNode;
  options?: Array<T>;
  eventHandler?: (val?: any) => void;
}

const NavContainer = <T,>({
  title,
  children,
  options = [],
  eventHandler = () => {},
  headerContent,
}: NavContainerProps<T>) => {
  const { userInfo, setCurrentAccount, currentAccount } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const { logOut } = useContext(AuthContext) as AuthenticationContext;
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;
  const { isMobile } = useViewport(862);

  const { data: config } = useGetConfig();
  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  const menuItems: Array<MenuItemsFields> = [
    {
      title: "Dashboard",
      icon: <WaffleIcon fill={colors.icons.default.fillColor} />,
      route: routes.dashboard,
    },
    ...(showBilling
      ? [
          {
            title: "Billing",
            icon: <BillingIcon fill={colors.icons.default.fillColor} />,
            route: routes.billing,
          },
        ]
      : []),
    {
      title: "Services",
      icon: <ServicesIcon fill={colors.icons.default.fillColor} />,
      route: routes.services,
    },
    {
      title: "Help",
      icon: <HelpIcon fill={colors.icons.default.fillColor} />,
      route: routes.help,
    },
  ];

  const menuOptions = [
    {
      label: "Account Settings",
      href: routes.profile.path,
      icon: (
        <PersonOutline
          sx={{ height: "28px", width: "28px", marginRight: "8px" }}
          fill={colors.icons.default.fillColor}
        />
      ),
    },
    {
      label: "Log out",
      href: "#",
      onClick: () => logOut(),
      icon: (
        <Logout
          sx={{
            height: "24px",
            width: "24px",
            marginRight: "8px",
            marginLeft: "4px",
          }}
          fill={colors.icons.default.fillColor}
        />
      ),
    },
  ];

  const {
    anchorEl: profileMenuAnchorEl,
    open: profileMenuOpen,
    handleClick: handleProfileMenuClick,
    handleClose: handleProfileMenuClose,
  } = useMenuAnchor();

  const handleAccountMenuClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAccountMenuOpen(true);
    handleProfileMenuClick(event);
  };
  const handleAccountMenuClose = () => {
    setAccountMenuOpen(false);
    handleProfileMenuClose();
  };

  const drawer = (
    <ParentContainer backgroundColor={colors.backgrounds.fallback}>
      <LeftMenuGradient backgroundColor={colors.backgrounds.asideGradient}>
        <NavBar title={title} menuItems={menuItems} />
        <Stack
          aria-hidden="true"
          flexDirection="row"
          bgcolor={colors.backgrounds.default}
          padding={"1.5rem"}
          flex={1}
          style={{ borderTopLeftRadius: 50 }}
        ></Stack>
      </LeftMenuGradient>
    </ParentContainer>
  );

  const accounts = userInfo?.data?.user?.Accounts;

  function renderSwitchAccountsListView() {
    if (accounts && accounts.length > 0) {
      const accountChoices = [
        <MenuItem
          key="Switch Accounts"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            gap: "18px",
            fontWeight: "bold",
            fontFamily: "Inter",
            padding: `${getREMFromPX(5)} ${getREMFromPX(15)}`,
          }}
        >
          <SwitchAccountsIcon fill={colors.icons.default.fillColor} />
          Switch Accounts
        </MenuItem>,
      ];

      accounts.forEach((account) => {
        if (account) {
          accountChoices.push(
            <li key={account.id}>
              <MenuItem
                component={Link}
                to="#"
                key={account.id}
                onClick={() => {
                  handleAccountMenuClose();
                  setCurrentAccount(account);
                }}
                aria-label={`Switch account to ${account.name}`}
              >
                {account.Devices && account.Devices.length > 0 && (
                  <Box
                    component="span"
                    sx={{ textAlign: "right", width: "100%" }}
                  >
                    {account?.Devices[0]?.Physicallocation?.street}
                  </Box>
                )}
                {(!account.Devices || account.Devices.length === 0) && (
                  <Box
                    component="span"
                    sx={{ textAlign: "right", width: "100%" }}
                  >
                    {account.name}
                  </Box>
                )}
              </MenuItem>
            </li>
          );
        }
      });

      return accountChoices;
    }

    return null;
  }

  return (
    <Box display="flex">
      <Hidden mdDown>{drawer}</Hidden>
      <Box
        sx={{
          padding: 0,
          margin: 0,
          paddingTop: isMobile ? "1.5rem" : "3.125rem",
          bgcolor: colors.backgrounds.default,
          position: "static",
          elevation: 0,
          width: "100%",
          maxWidth: "1800px",
        }}
      >
        <Toolbar component="header" sx={{ alignItems: "center" }}>
          <Stack sx={{ width: "100%" }}>
            {!!headerContent ? (
              headerContent
            ) : (
              <Stack
                sx={{
                  alignItems: "center",
                  gap: 2,
                  flexWrap: {
                    md: "nowrap",
                    xs: "wrap",
                  },
                }}
                flexDirection="row"
              >
                <Typography
                  variant="h2"
                  fontFamily="Montserrat"
                  fontSize={isMobile ? "1.5rem" : "2.5rem"}
                  fontWeight="500"
                  fontStyle="normal"
                  color={colors.textPrimary.heading}
                  sx={{
                    verticalAlign: "center",
                    padding: 0,
                  }}
                >
                  {title}
                </Typography>
                {options?.length > 0 && !isMobile && (
                  <Box
                    display="flex"
                    gap={1.5}
                    alignItems="center"
                    mt="8px"
                    width="100%"
                  >
                    {options.map((opt: any, idx) => (
                      <Button
                        variant="text"
                        key={idx}
                        onClick={() => eventHandler(idx)}
                        sx={{
                          fontSize: getREMFromPX(16),
                          fontWeight: opt.isActive ? "bold" : 600,
                          fontFamily: "Montserrat",
                          color: opt.isActive
                            ? colors.textPrimary.heading
                            : colors.form.primary,
                          textTransform: "none",
                          "&:hover": {
                            color: colors.form.primary,
                            backgroundColor: "transparent",
                          },
                          cursor: "pointer",
                          paddingLeft: 0,
                          paddingRight: 0,
                          height: "24px",
                          borderRadius: "4px",
                          background: "white",
                          padding: "14px 10px",
                          border: opt.isActive
                            ? `2px solid ${colors.textPrimary.heading}`
                            : `2px solid tranasperant`,
                        }}
                        data-cy={`navButton${opt?.label.replace(/\s+/g, "")}`}
                        aria-pressed={opt.isActive}
                      >
                        {opt?.label}
                      </Button>
                    ))}
                  </Box>
                )}
              </Stack>
            )}
          </Stack>
          <Box
            sx={{
              textAlign: "right",
              marginRight: isMobile ? 0 : 4,
            }}
          >
            {isMobile ? (
              <MenuIcon
                style={{
                  borderRadius: "30%",
                  cursor: "pointer",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
                fill={colors.icons.default.fillColor}
                onClick={handleProfileMenuClick}
              />
            ) : (
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    color: "black",
                    margin: "auto",
                    paddingRight: "1rem",
                  }}
                >
                  <Box component="span" onClick={handleProfileMenuClick}>
                    {currentAccount?.name}
                  </Box>
                  {accounts && accounts.length > 0 && (
                    <Box
                      sx={{
                        background: "#fff",
                        border: `1px solid ${colors.buttons.outline.border}`,
                        fontSize: 14,
                        fontWeight: 500,
                        color: colors.form.primary,
                        textAlign: "center",
                        borderRadius: "4px",
                        width: "fit-content",
                        marginLeft: "auto",
                        padding: "2px 16px",
                        fontFamily: "Inter",
                      }}
                      onClick={handleAccountMenuClick}
                      data-testid="switch-accounts-btn"
                    >
                      Switch Accounts
                    </Box>
                  )}
                </Box>
                <UserProfileIcon
                  onClick={handleProfileMenuClick}
                  style={{
                    borderRadius: "30%",
                    cursor: "pointer",
                    width: "2.5rem",
                    height: "2.5rem",
                  }}
                  fill={colors.icons.default.fillColor}
                  data-cy="navcontainer-user-icon"
                  data-testid="navcontainer-user-icon"
                />
              </Box>
            )}
            <Hidden mdUp>
              <Menu
                id="profile-menu"
                anchorEl={profileMenuAnchorEl}
                open={profileMenuOpen && !accountMenuOpen}
                onClose={handleProfileMenuClose}
                MenuListProps={menuListProps}
              >
                {menuItems.map(({ title, route }) => (
                  <li key={title}>
                    <MenuItem component={Link} to={route.path}>
                      {title}
                    </MenuItem>
                  </li>
                ))}

                {menuOptions.map(({ label, href, icon }) => (
                  <li key={label}>
                    <MenuItem component={Link} to={href}>
                      {icon}
                      {label}
                    </MenuItem>
                  </li>
                ))}
                {renderSwitchAccountsListView()}
              </Menu>
            </Hidden>

            <Hidden mdDown>
              <Menu
                id="profile-menu"
                anchorEl={profileMenuAnchorEl}
                open={profileMenuOpen && !accountMenuOpen}
                onClose={handleProfileMenuClose}
                MenuListProps={menuListProps}
              >
                {menuOptions.map(({ label, onClick, href, icon }) => (
                  <li key={label}>
                    <MenuItem
                      component={Link}
                      to={href}
                      onClick={() => {
                        handleProfileMenuClose();
                        onClick?.();
                      }}
                    >
                      {icon}
                      {label}
                    </MenuItem>
                  </li>
                ))}
              </Menu>
            </Hidden>

            <Menu
              id="profile-menu"
              anchorEl={profileMenuAnchorEl}
              open={profileMenuOpen && accountMenuOpen}
              onClose={handleAccountMenuClose}
              MenuListProps={menuListProps}
            >
              {renderSwitchAccountsListView()}
            </Menu>
          </Box>
        </Toolbar>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            bgcolor: colors.backgrounds.default,
            color: "black",
            paddingBottom: "4.5rem",
          }}
        >
          {children}
          <Box sx={{ marginTop: "2.25rem" }}>
            <EntryPointLink />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NavContainer;
