import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import CardWrapper from "../../../../components/CardWrapper";
import { ServiceProviderIcon } from "../../../../assets/icons";
import { routes } from "../../../../App";
import { Grid, SxProps } from "@mui/material";
import {
  ContinueIcon,
  Description,
  ImageContainer,
  SubDescription,
  SupportInfo,
  iconStyles,
  IconText,
  ButtonService,
} from "../../Styles";
import { useGetMySubscriptions } from "../../../../hooks/useGetMySubscriptions";
import useGetUserId from "../../../../hooks/useGetUserId";
import sendErrorToast from "../../../../utils/sendErrorToast";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { LogoContainer } from "../../../../routes/Services/components/CardService";
import { Email, Language, LocalPhone } from "@mui/icons-material";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";

const ServiceProviders = ({
  styles,
}: {
  children?: React.ReactElement;
  styles: SxProps;
}) => {
  const navigate = useNavigate();
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const userId = useGetUserId();

  const { selectedAccountId } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { data: subscriptionsData } = useGetMySubscriptions(
    userId,
    selectedAccountId,
    {
      enabled: !!userId && !!selectedAccountId,
      refetchOnWindowFocus: false,
      onError: () =>
        sendErrorToast(
          "There was an error getting the subscriptions information, please try again"
        ),
    }
  );

  const subscriptionLength = subscriptionsData?.data?.subscription?.length;
  const support_url =
    subscriptionsData?.data?.subscription?.at(0)?.Serviceplan?.Service
      ?.support_url;
  const email =
    subscriptionsData?.data?.subscription?.at(0)?.Serviceplan?.Service.Provider
      ?.Contacts[0]?.email;
  const phone =
    subscriptionsData?.data?.subscription?.at(0)?.Serviceplan?.Service.Provider
      ?.Contacts[0]?.phone;

  const arrowAction = subscriptionLength ? (
    <Link
      to={routes.helpService.path}
      aria-label="Goto Service Provider Info page"
      data-cy="help-providers-arrow"
    >
      <ContinueIcon fill={colors.icons.default.fillColor} />
    </Link>
  ) : (
    ""
  );

  const serviceAction = (
    <ButtonService
      onClick={() => navigate(routes.services.path)}
      sx={{ pointerEvents: "auto", color: "#fff" }}
    >
      Services
    </ButtonService>
  );

  return (
    <CardWrapper
      title="Service Provider Info"
      actions={arrowAction}
      sx={styles}
    >
      <Grid
        container
        spacing={2}
        onClick={() => {
          subscriptionLength
            ? navigate(routes.helpService.path)
            : navigate(routes.services.path);
        }}
        sx={{
          flexDirection: "row",
          flexWrap: "nowrap",
        }}
      >
        <Grid item sm={6} md={6}>
          {subscriptionLength > 1 ? (
            <Description>
              Need help with <br /> your service?
            </Description>
          ) : subscriptionLength === 1 ? (
            <Description
              sx={{
                marginTop: "22px",
              }}
            >
              <p>
                Need help with <br /> your service?
                <SubDescription>
                  Please contact your service <br />
                  provider.
                </SubDescription>
              </p>
            </Description>
          ) : (
            <Description>
              <div>
                You have not <br /> subscribed to any <br /> services <br />
                <SubDescription>
                  please head to services <br /> page to subscribe
                </SubDescription>
                {serviceAction}
              </div>
            </Description>
          )}
        </Grid>
        <Grid
          sx={{
            marginTop: "1rem",
          }}
          item
          sm={6}
          md={6}
        >
          {subscriptionLength === 1 ? (
            <div>
              <LogoContainer width="100%" height="100%">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/provider/${
                    subscriptionsData?.data?.subscription?.at(0)?.Serviceplan
                      ?.Service.Provider?.id
                  }.png`}
                  alt=""
                />
              </LogoContainer>
              {support_url ? (
                <SupportInfo>
                  <Language
                    sx={iconStyles}
                    fill={colors.icons.default.fillColor}
                  />
                  <IconText
                    href={support_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {support_url}
                  </IconText>
                </SupportInfo>
              ) : null}
              {email ? (
                <SupportInfo>
                  <Email
                    sx={iconStyles}
                    fill={colors.icons.default.fillColor}
                  />
                  <IconText href={`mailto:${email}`}>{email}</IconText>
                </SupportInfo>
              ) : null}

              {phone ? (
                <SupportInfo>
                  <LocalPhone
                    sx={iconStyles}
                    fill={colors.icons.default.fillColor}
                  />
                  <IconText
                    sx={{ color: "#000", textDecoration: "none" }}
                    href={`tel:+1${phone}`}
                  >
                    {phone}
                  </IconText>
                </SupportInfo>
              ) : null}
            </div>
          ) : (
            <ImageContainer>
              <ServiceProviderIcon
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  marginBottom: "1.84rem",
                  marginTop: "-1.84rem",
                }}
                fill={colors.icons.default.fillColor}
              />
            </ImageContainer>
          )}
        </Grid>
      </Grid>
    </CardWrapper>
  );
};
export default ServiceProviders;
