import useGetThemePath from "../../../hooks/useGetThemePath";
import { theme } from "../../../theme";
import getREMFromPX from "../../../utils/getREMFromPX";
import { Typography, Stack } from "@mui/material";

const DetailRow = ({
  label,
  value,
  value2,
  value3,
}: {
  label: string;
  value: string;
  value2?: string;
  value3?: string;
}) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "flex-start" }}>
      <Typography
        sx={{
          flex: 1,
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(18),
          fontWeight: "600",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.textPrimary.heading,
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          flex: 1.5,
          fontFamily: "Montserrat",
          fontSize: 16,
          fontWeight: "500",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.textPrimary.heading,
        }}
      >
        {value} &nbsp;
        {value2 && (
          <>
            <br />
            {value2}
          </>
        )}
        {value3 && (
          <>
            <br />
            {value3}
          </>
        )}
      </Typography>
    </Stack>
  );
};
export default DetailRow;
