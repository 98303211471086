import { Stack, Typography } from "@mui/material";
import useGetThemePath from "../../hooks/useGetThemePath";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../App";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import useViewport from "../../hooks/useViewport";

const HeaderContent = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const { userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { isMobile } = useViewport();

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo?.data?.user?.force_password) {
      navigate(routes.updateAccount.path);
    }
  }, [userInfo, navigate]);

  const userName = userInfo?.data?.user?.Contact?.first_name || "";
  const location = userInfo?.data?.user?.Contact?.Location;

  const composedAddress = location
    ? `${location.city}, ${location.state} ${location.zip}`
    : "";

  return (
    <Stack>
      <Typography
        variant="h2"
        sx={{
          fontFamily: "Montserrat",
          fontSize: getREMFromPX(isMobile ? 18 : 40),
          fontWeight: "600",
          fontStyle: "normal",
          letterSpacing: 0,
          textAlign: "left",
          color: colors.textPrimary.heading,
        }}
      >
        Hey{" "}
        <span style={{ color: colors.textPrimary.headingAlt }}>
          {userName}!
        </span>
      </Typography>
      <Stack
        direction="row"
        spacing={getREMFromPX(theme.spacing * 2)}
        mt="0 !important"
        width="100%"
      >
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: getREMFromPX(isMobile ? 12 : 20),
            fontWeight: "500",
            fontStyle: "normal",
            letterSpacing: 0,
            textAlign: "left",
            color: colors.textPrimary.heading,
          }}
        >
          {composedAddress}
        </Typography>
      </Stack>
    </Stack>
  );
};
export default HeaderContent;
