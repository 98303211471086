import { Stack, Typography, Grid, Box } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Port } from "../../../api/interfaces/Port";
import { routes } from "../../../App";
import {
  AaeonDeviceFront,
  VenoenDeviceFront,
  ONTDeviceFront,
  AaeonDeviceBack,
  VenoenDeviceBack,
  ONTDeviceBack,
} from "../../../assets/device";
import {
  ForwardArrowIcon,
  RotateIcon,
  RouterIcon,
} from "../../../assets/icons";
import CardWrapper from "../../../components/CardWrapper";
import useArePortsLive from "../../../hooks/useArePortsLive";
import useGetThemePath from "../../../hooks/useGetThemePath";
import { theme } from "../../../theme";
import getREMFromPX from "../../../utils/getREMFromPX";
import { Device } from "../../../api/interfaces/Device";

const DeviceInfo = ({ device }: { device: Device }) => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const wanPort = (device?.Portinterfaces as Port[])?.filter((port) => {
    return port.name === "WAN";
  });
  const { data } = useArePortsLive(wanPort);
  const isLive =
    data[0]?.data?.portinterface?.live?.link_state === "up" || false;

  const [showFront, setShowFront] = useState(true);

  const navigate = useNavigate();

  const arrowAction = (
    <ForwardArrowIcon
      onClick={() => navigate(routes.helpDevice.path)}
      style={{ cursor: "pointer" }}
      data-cy="dashboard-my-device"
      fill={colors.icons.default.fillColor}
    />
  );

  const getDeviceFront = () => {
    switch (device?.Product?.Manufacturer?.name) {
      case "Aaeon":
        return AaeonDeviceFront;
      case "Venoen":
        return VenoenDeviceFront;
      case "Kontron":
        return ONTDeviceFront;
      default:
        return AaeonDeviceFront;
    }
  };

  const getDeviceBack = () => {
    switch (device?.Product?.Manufacturer?.name) {
      case "Aaeon":
        return AaeonDeviceBack;
      case "Venoen":
        return VenoenDeviceBack;
      case "Kontron":
        return ONTDeviceBack;
      default:
        return AaeonDeviceBack;
    }
  };

  const DeviceFront = getDeviceFront();
  const DeviceBack = getDeviceBack();
  const DeviceImage = showFront ? DeviceFront : DeviceBack;

  const ports = (device?.Portinterfaces as Port[])?.filter(
    (portinterface) =>
      portinterface.display === true &&
      (portinterface.type === "LAN" || portinterface.type === "WAN")
  );

  const { data: portCollection } = useArePortsLive(ports);

  if (!device) {
    return <>Loading...</>;
  }

  return (
    <Grid item xl={12}>
      <CardWrapper
        sx={{
          backgroundColor: isLive
            ? colors.white
            : colors.notification.error.accent,
        }}
        title="Device Info"
        actions={arrowAction}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={6} sm={5} md={4}>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: getREMFromPX(20),
                fontWeight: "600",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "left",
                color: colors.textPrimary.heading,
                marginTop: getREMFromPX(15),
                marginBottom: getREMFromPX(29),
              }}
            >
              Virtual Broadband Gateway
            </Typography>
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <RouterIcon fill={colors.icons.default.fillColor} />
              <Typography
                sx={{
                  marginLeft: getREMFromPX(15),
                  fontFamily: "Montserrat",
                  fontSize: getREMFromPX(16),
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  textAlign: "left",
                  color: colors.textPrimary.heading,
                }}
              >
                {device?.type} {device?.hardware_version}
              </Typography>
            </Stack>
          </Grid>
          <Box
            sx={{
              width: "50%",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <DeviceImage ports={portCollection} />
            </Box>
            <Stack
              onClick={() => setShowFront(!showFront)}
              sx={{
                marginTop: getREMFromPX(-30),
                width: "100%",
                maxWidth: getREMFromPX(550),
                paddingRight: getREMFromPX(30),
                alignSelf: "center",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "row",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  marginRight: getREMFromPX(3),
                  background: "#fff",
                  border: `1px solid ${colors.buttons.outline.border}`,
                  fontSize: 14,
                  fontWeight: 500,
                  color: colors.form.primary,
                  textAlign: "center",
                  borderRadius: "4px",
                  width: "fit-content",
                  marginLeft: "auto",
                  padding: "2px 16px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Rotate
                <RotateIcon height={16} fill={colors.icons.default.fillColor} />
              </Typography>
            </Stack>
          </Box>
        </Box>
      </CardWrapper>
    </Grid>
  );
};

export default DeviceInfo;
